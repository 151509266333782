<template>
  <div class="notifications">
    <h6 class="card-title">Notifications</h6>
    <div class="n-body">
      <div class="card" v-for="(item, key) in notifications" :key="key">
        <div class="card-body">
          <div class="card-icon">
            <i class="far fa-user-circle"></i>
          </div>
          <div class="card-content">
            <p>{{ item.notification }}</p>
            <span class="text-muted">{{
              formatDateTimeSecond(item.date)
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NoData from "@/components/dashboard/noData.vue";
export default {
  // components: { NoData },
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    getNotifications() {
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("get", "user/notifications/")
        .then((resp) => {
          this.$store.commit("setLoader", false);
          this.notifications = resp.data;
        });
    },
  },
  created() {
    this.getNotifications();
  },
};
</script>

<style scoped lang="scss">
.card {
  margin-bottom: 10px;
  .card-body {
    display: flex;
    align-items: center;
    .card-icon {
      i {
        font-size: 20px;
      }
    }
    .card-content {
      margin-left: 18px;
      p {
        margin-bottom: 0;
        font-size: 15px;
      }
      span {
        font-size: 14px;
      }
    }
  }
}
</style>
