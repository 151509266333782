<template>
  <section class="profile d-grid manage">
    <div class="tp-setting">
      <div class="appr-req">
        <span>Settings</span>
        <h6 class="mb-0">Profile</h6>
      </div>
    </div>
    <div class="profile--content" v-if="loaded">
      <aside class="profile--content_left">
        <div>
          <div
            class="profile--content_left_top d-flex justify-content-center align-items-center"
          >
            <div
              class="profile-img d-flex justify-content-center align-items-center"
            >
              <p class="mb-0">
                {{ user.first_name.charAt(0).toUpperCase()
                }}{{ user.last_name.charAt(0).toUpperCase() }}
              </p>
            </div>
            <div>
              <h6 class="p-name">{{ user.first_name }} {{ user.last_name }}</h6>
              <h6 class="p-name">{{ user.email }}</h6>
            </div>
          </div>
          <div
            class="role-wrapper d-flex flex-wrap justify-content-center w-100 mt-3"
          >
            <div
              class="role d-flex flex-wrap justify-content-center"
              v-for="(role, index) in user_state.role_id"
              :key="index"
            >
              <span class="badge bg-gray" v-if="role == 44">Admin</span>
              <span class="badge bg-lightblue" v-if="role == 14">Approver</span>
              <span class="badge bg-lightblue" v-if="role == 4">Requester</span>
              <span class="badge bg-lightblue" v-if="role == 54">Endorser</span>
              <span class="badge bg-gray" v-if="role == 34">Tech</span>
              <span class="badge bg-gray" v-if="role == 24">Finance</span>
            </div>
          </div>
        </div>
      </aside>
      <aside class="profile--content_right">
        <ul
          class="nav nav-pills justify-content-center align-items-center"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              <span>Profile</span>
            </button>
          </li>
          <!--<li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="preference-tab"
              data-bs-toggle="pill"
              data-bs-target="#preference"
              type="button"
              role="tab"
              aria-controls="preference"
              aria-selected="false"
            >
              <span>Preference</span>
            </button>
          </li> -->
        </ul>

        <div class="tab-content" id="pills-tabContent">
          <!-- profile -->
          <div
            class="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="updateProfile()">
                  <div class="input-flex">
                    <div class="form-group">
                      <label for="">First Name <small>*</small> </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="user.first_name"
                      />
                    </div>
                    <div class="form-group">
                      <label for="">Last Name <small>*</small></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="user.last_name"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="user.email"
                      disabled
                    />
                  </div>
                  <div class="input-flex">
                    <div class="form-group">
                      <!-- <div>
                        <select v-model="countryVal" @change="changeCountry">
                          <option
                            v-for="country in countriesWithFlag"
                            :key="country.value"
                            :value="country.value"
                          >
                            <img
                              :src="getFlagUrl(country.image)"
                              alt="Flag"
                              width="200"
                              height="200"
                            />

                            {{ country.code + country.codeSuffixes }}
                          </option>
                        </select>
                      </div> -->
                      <label for="">Phone Number <small>*</small> </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="user.phone"
                      />
                    </div>
                    <div
                      class="form-group"
                      v-if="!checkPermissions('84', user_state.role_id)"
                    >
                      <label for="">Department </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="user.department"
                      />
                    </div>
                  </div>
                  <div class="form-btn">
                    <button
                      class="btn btn-primary f-13 px-3"
                      :disabled="loading"
                    >
                      <span v-if="!loading">Save Changes</span>
                      <span v-else>Loading...</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center password-group"
            >
              <h4 class="password-group_title mb-0">Password</h4>
              <div class="">
                <button
                  class="btn btn-primary-outline f-13 px-3"
                  :disabled="loading"
                  @click.prevent="showPasswordModal = true"
                >
                  <span v-if="!loading">Update Password</span>
                  <span v-else>Loading...</span>
                </button>
              </div>
            </div>
          </div>
          <!-- preference -->
          <div
            class="tab-pane fade"
            id="preference"
            role="tabpanel"
            aria-labelledby="preference-tab"
            v-if="checkPermissions('44', user_state.role_id)"
          >
            <div class="card">
              <div class="card-body pre-box">
                <div class="d-grid gap-4">
                  <div class="d-grid gap-3">
                    <h4 class="mb-0 pre-title">Email Notifications</h4>
                    <div class="d-flex gap-1">
                      <div class="notify-svg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9 0.75C4.44375 0.75 0.75 4.44375 0.75 9C0.75 13.5562 4.44375 17.25 9 17.25C13.5562 17.25 17.25 13.5562 17.25 9C17.25 4.44375 13.5562 0.75 9 0.75ZM8.625 4.5C8.42609 4.5 8.23532 4.57902 8.09467 4.71967C7.95402 4.86032 7.875 5.05109 7.875 5.25C7.875 5.44891 7.95402 5.63968 8.09467 5.78033C8.23532 5.92098 8.42609 6 8.625 6H9C9.19891 6 9.38968 5.92098 9.53033 5.78033C9.67098 5.63968 9.75 5.44891 9.75 5.25C9.75 5.05109 9.67098 4.86032 9.53033 4.71967C9.38968 4.57902 9.19891 4.5 9 4.5H8.625ZM7.5 7.5C7.30109 7.5 7.11032 7.57902 6.96967 7.71967C6.82902 7.86032 6.75 8.05109 6.75 8.25C6.75 8.44891 6.82902 8.63968 6.96967 8.78033C7.11032 8.92098 7.30109 9 7.5 9H8.25V11.25H7.5C7.30109 11.25 7.11032 11.329 6.96967 11.4697C6.82902 11.6103 6.75 11.8011 6.75 12C6.75 12.1989 6.82902 12.3897 6.96967 12.5303C7.11032 12.671 7.30109 12.75 7.5 12.75H10.5C10.6989 12.75 10.8897 12.671 11.0303 12.5303C11.171 12.3897 11.25 12.1989 11.25 12C11.25 11.8011 11.171 11.6103 11.0303 11.4697C10.8897 11.329 10.6989 11.25 10.5 11.25H9.75V8.25C9.75 8.05109 9.67098 7.86032 9.53033 7.71967C9.38968 7.57902 9.19891 7.5 9 7.5H7.5Z"
                            fill="#022438"
                          />
                        </svg>
                      </div>

                      <p class="mb-0 text-16">
                        You cannot make changes to the notify preference because
                        the access has been disabled by your admin
                      </p>
                    </div>
                    <div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-0 text-16">Send notification by emails</p>
                        <div class="recurr-toggle">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <p class="pre-text mb-0">
                        Get emails on all activity going on with in your
                        organization's account on BuySimply . You can turn them
                        off anytime.
                      </p>
                    </div>
                  </div>
                  <p class="mb-0 text-16">Notify me when;</p>
                  <div class="pre-group d-grid">
                    <div class="d-flex justify-content-between">
                      <p class="mb-0 text-18">Request is raised</p>
                      <input type="checkbox" name="" id="" />
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0 text-18">Request has been endorsed</p>
                      <input type="checkbox" name="" id="" />
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0 text-18">Request has been approved</p>
                      <input type="checkbox" name="" id="" />
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="mb-0 text-18">
                        Action is done on a copied request
                      </p>
                      <input type="checkbox" name="" id="" />
                    </div>
                  </div>
                  <div>
                    <h5 class="mb-12 text-18 text-color-gray">
                      BuySimply product tips and news
                    </h5>
                    <div class="d-flex justify-content-between ps-20">
                      <p class="mb-0 text-16">
                        Send me occasional emails with news and feature updates
                        from BuySimply
                      </p>
                      <input type="checkbox" name="" id="" />
                    </div>
                  </div>
                </div>
                <div>
                  <h4 class="mb-0 pre-title">QuickLinks</h4>
                  <div class="d-grid gap-3">
                    <p class="pre-text mb-0">
                      The Quicklinks button provides easy access to your
                      frequent action.
                    </p>
                    <div class="d-grid gap-3">
                      <h5 class="mb-0 text-18">Manage QuickLinks</h5>
                      <div class="pre-role d-flex">
                        <span class="badge bg-gray">Bulk Online Payment</span>
                        <span class="badge bg-gray">Bulk Payment Record</span>
                      </div>
                      <div class="pre-role d-flex flex-wrap">
                        <div class="pre-manage">
                          <span>Add Payee</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="21"
                              height="21"
                              rx="10.5"
                              fill="#DC3646"
                            />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="21"
                              height="21"
                              rx="10.5"
                              stroke="#DFDFDF"
                            />
                            <path
                              d="M15 7.5L8 14.5M8 7.5L15 14.5"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="pre-manage">
                          <span>Create Expense Category</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="21"
                              height="21"
                              rx="10.5"
                              fill="#DC3646"
                            />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="21"
                              height="21"
                              rx="10.5"
                              stroke="#DFDFDF"
                            />
                            <path
                              d="M15 7.5L8 14.5M8 7.5L15 14.5"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="pre-manage">
                          <span>Approval Circles</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="21"
                              height="21"
                              rx="10.5"
                              fill="#DC3646"
                            />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="21"
                              height="21"
                              rx="10.5"
                              stroke="#DFDFDF"
                            />
                            <path
                              d="M15 7.5L8 14.5M8 7.5L15 14.5"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="pre-manage">
                          <span>Join Requests</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="21"
                              height="21"
                              rx="10.5"
                              fill="#DC3646"
                            />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="21"
                              height="21"
                              rx="10.5"
                              stroke="#DFDFDF"
                            />
                            <path
                              d="M15 7.5L8 14.5M8 7.5L15 14.5"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="pre-manage">
                          <span>Billing</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="21"
                              height="21"
                              rx="10.5"
                              fill="#DC3646"
                            />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="21"
                              height="21"
                              rx="10.5"
                              stroke="#DFDFDF"
                            />
                            <path
                              d="M15 7.5L8 14.5M8 7.5L15 14.5"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pre-more d-grid">
                    <h4 class="mb-0 text-16">Add more</h4>
                    <div class="d-flex pre-select-btn">
                      <select name="" id="" class="select-option">
                        <option value="1" selected>select option</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                      <button
                        class="btn btn-primary f-13 px-3 add-link-btn"
                        :disabled="loading"
                      >
                        <span v-if="!loading">Add link</span>
                        <span v-else>Loading...</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>

    <!-- Password pop up -->
    <div v-if="showPasswordModal" class="overlay">
      <div class="password-modal-container">
        <div class="inner-modal">
          <form @submit.prevent="updatePassword()" class="password-card">
            <div>
              <h3 class="password-card_tittle">Update Password</h3>
              <div class="form-group">
                <label for="">Current Password</label>
                <div class="input-group">
                  <input
                    :type="pswType"
                    class="form-control input-psw"
                    placeholder="Password"
                    v-model="password.password"
                    required
                  />
                  <span
                    class="input-group-text"
                    id="togglePsw"
                    @click.prevent="togglePsw('pswType')"
                  >
                    <i class="fa fa-eye" v-if="pswType == 'password'"></i>
                    <i class="fa fa-eye-slash" v-else></i>
                  </span>
                </div>
                <!-- <input
                          type="password"
                          v-model="password.password"
                          class="form-control"
                        /> -->
              </div>
              <div class="form-group">
                <label for="">New Password</label>
                <div class="input-group">
                  <input
                    :type="new_pswType"
                    class="form-control input-psw"
                    placeholder="Password"
                    v-model="password.new_password"
                    required
                  />
                  <span
                    class="input-group-text"
                    id="togglePsw"
                    @click.prevent="togglePsw('new_pswType')"
                  >
                    <i class="fa fa-eye" v-if="new_pswType == 'password'"></i>
                    <i class="fa fa-eye-slash" v-else></i>
                  </span>
                </div>
                <!-- <input
                          type="password"
                          v-model="password.new_password"
                          class="form-control"
                        /> -->
              </div>
              <div class="form-group">
                <label for="">Confirm New Password</label>
                <div class="input-group">
                  <input
                    :type="confirm_pswType"
                    class="form-control input-psw"
                    placeholder="Password"
                    v-model="password.confirm_password"
                    required
                  />
                  <span
                    class="input-group-text"
                    id="togglePsw"
                    @click.prevent="togglePsw('confirm_pswType')"
                  >
                    <i
                      class="fa fa-eye"
                      v-if="confirm_pswType == 'password'"
                    ></i>
                    <i class="fa fa-eye-slash" v-else></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-btn">
              <button class="btn btn-primary f-14" :disabled="loading">
                <span v-if="!loading">Save Changes</span>
                <span v-else>Loading...</span>
              </button>
            </div>
          </form>
          <button class="btn password-close" @click.prevent="closeModal">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="41"
              height="40"
              viewBox="0 0 41 40"
              fill="none"
            >
              <rect
                x="1"
                y="0.5"
                width="39"
                height="39"
                rx="19.5"
                fill="white"
              />
              <rect
                x="1"
                y="0.5"
                width="39"
                height="39"
                rx="19.5"
                stroke="#DFDFDF"
              />
              <path
                d="M25.5 15L15.5 25M15.5 15L25.5 25"
                stroke="#6A6A6A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapState } from "vuex";
  import countriesData from "@/data/country.json";
  import axios from "axios";
  // import Multiselect from "vue-multiselect";
  export default {
    //   components: {
    //     Multiselect,
    //   },
    computed: {
      ...mapState({
        user_state: (context) => context.user,
      }),
    },
    data() {
      return {
        loading: false,
        user: {},
        loaded: false,
        states: [],
        cities: [],
        countries: [],
        password: {
          new_password: "",
          password: "",
          confirm_password: "",
        },
        nigeriaState: [],
        lga: [],
        company: {
          company_name: "",
          company_address: "",
          country: "Nigeria",
          registration_number: "",
          vat_number: "",
          tax_exempt: "",
          delivery_address: "",
          phone: "",
          state: {},
          city: {},
          lga: {},
        },
        kyc: {
          first_name: "",
          last_name: "",
          bvn: "",
        },
        sCompany: { name: "Nigeria", id: "161" },
        new_pswType: "password",
        confirm_pswType: "password",
        pswType: "password",

        // password modal
        showPasswordModal: false,

        // countries code and flag
        countriesWithFlag: [],
        countryVal: "GBR",
      };
    },
    methods: {
      togglePsw(type) {
        if (type == "new_pswType") {
          this.new_pswType == "password"
            ? (this.new_pswType = "text")
            : (this.new_pswType = "password");
        }

        if (type == "confirm_pswType") {
          this.confirm_pswType == "password"
            ? (this.confirm_pswType = "text")
            : (this.confirm_pswType = "password");
        }

        if (type == "pswType") {
          this.pswType == "password"
            ? (this.pswType = "text")
            : (this.pswType = "password");
        }
      },
      setState(event) {
        this.company.country = event.name;
        if (event.name != "Nigeria") {
          this.states = event.states;
          this.states.map((item) => {
            item.state = item.name;
          });
        } else {
          this.states = this.nigeriaState;
        }
      },
      getUser() {
        this.$store.dispatch("getUser").then((resp) => {
          this.loaded = true;
          var data = {
            user: resp.data.user,
            profile: resp.data.profile,
            token: this.$store.state.token,
          };
          this.$store.commit("setUser", data);
        });
      },

      updateCompanyProfile() {
        // this.company.state = JSON.stringify(this.company.state);
        // this.company.city = JSON.stringify(this.company.city);
        if (!this.company.country || this.company.country.length < 1) {
          window.ToasterAlert("error", "Organization country is required");
          return false;
        }

        if (!this.company.city || !this.company.city.id) {
          window.ToasterAlert("error", "Organization city is required");
          return false;
        }

        if (!this.company.state || !this.company.state.id) {
          window.ToasterAlert("error", "Organization state is required");
          return false;
        }

        if (!this.company.city || this.company.city.length < 1) {
          window.ToasterAlert("error", "Organization city is required");
          return false;
        }
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: "users/companyprofile/create/",
            details: {
              ...this.company,
              state: JSON.stringify(this.company.state),
              city: JSON.stringify(this.company.city),
              lga: JSON.stringify(this.company.lga),
            },
          })
          .then(async (resp) => {
            await this.getUser();
            this.loading = false;
            if (resp.data) {
              window.ToasterAlert(
                "success",
                "Organization profile updated successfully"
              );
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      verifyKYC() {
        // this.company.state = JSON.stringify(this.company.state);
        // this.company.city = JSON.stringify(this.company.city);
        if (!this.kyc.first_name || this.kyc.first_name.length < 1) {
          window.ToasterAlert(
            "error",
            "Organization contact first name is required"
          );
          return false;
        }

        if (!this.kyc.last_name || !this.kyc.last_name.length < 1) {
          window.ToasterAlert(
            "error",
            "Organization contact last name is required"
          );
          return false;
        }

        if (!this.kyc.bvn || !this.kyc.bvn.length < 1) {
          window.ToasterAlert("error", "Organization contact bvn is required");
          return false;
        }
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: "company/kycverify/",
            details: {
              ...this.kyc,
            },
          })
          .then(async (resp) => {
            await this.getUser();
            this.loading = false;
            if (resp.data) {
              window.ToasterAlert(
                "success",
                "Organization KYC updated successfully"
              );
            }
          })
          .catch(() => {
            this.loading = false;
            window.ToasterAlert(
              "error",
              "Organization KYC verification failed, please contact help"
            );
          });
      },
      updatePassword() {
        if (
          !this.password.password ||
          !this.password.new_password ||
          !this.password.confirm_password
        ) {
          window.ToasterAlert("error", "All fields are required");
          return false;
        }
        if (this.password.new_password == this.password.password) {
          window.ToasterAlert(
            "error",
            "Your new password cannot be the same as your current password"
          );
          return false;
        }
        if (this.password.new_password != this.password.confirm_password) {
          window.ToasterAlert("error", "Password confirmation does not match");
          return false;
        }
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: "users/changepassword/",
            details: this.password,
          })
          .then((resp) => {
            this.loading = false;
            if (resp.data) {
              this.password.password = "";
              this.password.confirm_password = "";
              this.password.new_password = "";
              window.ToasterAlert("success", "Password updated successfully");
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$store.dispatch("handleError", err);
          });
      },
      updateProfile() {
        if (!this.user.phone || this.user.phone.length < 7) {
          window.ToasterAlert("error", "Phone field is required");
          return false;
        }
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: "users/userprofile/create/",
            details: this.user,
          })
          .then(async (resp) => {
            await this.getUser();
            this.loading = false;
            if (resp.data) {
              window.ToasterAlert("success", "Profile updated successfully");
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$store.dispatch("handleError", err);
          });
      },
      getProfile() {
        this.$store.commit("setLoader", true);
        this.$store
          .dispatch(
            "get",
            "users/userprofile/read/"
          )
          .then((resp) => {
            this.$store.commit("setLoader", false);
            this.loaded = true;
            this.user = resp.data;

            if (!resp.data) {
              window.SweetAlert("error", "User not found, Please login");
              setTimeout(() => {
                this.$store.commit("logout");
              }, 4000);
            }
          });
      },
      getCompanyProfile() {
        this.$store
          .dispatch(
            "get",
            "users/companyprofile/read/"
          )
          .then((resp) => {
            this.company = resp.data[0];
            this.company.state = JSON.parse(this.company.state);
            this.company.city = JSON.parse(this.company.city);
            this.company.lga = JSON.parse(this.company.lga);
            if (!this.company.country) {
              this.company.country = this.sCompany.name;
            }
          });
      },
      async getStates() {
        await this.$store.dispatch("get", "jumia/getstates").then((resp) => {
          this.nigeriaState = resp.data;
          this.states = resp.data;
        });
      },
      async getKongaCities(state) {
        await this.$store
          .dispatch("get", "konga/getcities/" + state.state)
          .then((resp) => {
            this.lga = resp.data;
          });
      },
      getCities(event) {
        if (this.company.country != "Nigeria") {
          this.cities = event.cities;
          this.company.state = { id: event.id, state: event.name };
          return false;
        } else {
          if (event) {
            this.company.state = event;
          } else {
            this.company.state = { id: 25, state: "Lagos" };
          }

          this.getKongaCities(this.company.state);

          axios
            .get(
              `https://www.jumia.com.ng/region/${this.company.state.id}/cities/`,
              {
                headers: { Accept: "application/json" },
              }
            )
            .then((resp) => {
              // this.$store.commit("setLoader", false);
              this.cities = resp.data.cities;
              // var a = this.cities.find(
              //   (c) => c.name == this.product.delivery_location
              // );
              // this.city = a;
              this.$forceUpdate();
            });
        }
      },
      // password modal
      closeModal() {
        console.log("open");
        this.showPasswordModal = false;
      },
      // countries code and flag
      changeCountry(event) {
        this.countryVal = event.target.value;
      },
      getCountries() {
        axios
          .get("https://restcountries.com/v3.1/all")
          .then((response) => {
            const suffix = response.data[0].idd.suffixes[0];
            const cleaned = response.data.map((item) => ({
              text: item.name.common,
              value: item.cca3,
              image: item.flags.svg,
              code: item.idd.root,
              codeSuffixes: suffix,
            }));
            cleaned.sort((a, b) => a.text.localeCompare(b.text));
            this.countriesWithFlag = cleaned;
          })
          .catch((error) => {
            console.error(error);
          });
      },
      getFlagUrl(image) {
        // Handle potential issues with image URLs
        return image ? image : "https://flagcdn.com/bf.svg";
      },
    },
    created() {
      // console.log(this.user_state);
      this.countries = countriesData;
      this.getProfile();
      this.getCompanyProfile();
      this.getStates();
      this.getCities();
      this.getCountries();
    },
  };
</script>

<style lang="scss" scoped>
  .badge {
    padding: 4px 10px !important;
    border-radius: 12px;
  }
  label {
    font-size: 13px;
  }
  .bg-lightblue {
    background: #d7f0ff;
  }
  .bg-gray {
    background: #ececec;
  }
  .text-color-gray {
    color: #6a6a6a !important;
  }
  .text-16 {
    color: #022438;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
  }
  .text-18 {
    color: #022438;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .form-control {
    border-radius: 6px !important;
    font-size: 13px;
  }
  .profile {
    padding: 10px 3px;
    gap: 24px;
  }
  .profile--subtitle {
    color: #022438;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
  }
  .profile--content_left {
    display: grid;
    padding: 20px;
    gap: 16px;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #dfdfdf;
    background: #fdfdff;
  }
  .profile--content_left_top {
    gap: 10px;
    margin-bottom: 1rem;
  }
  .profile--content_right {
    border-radius: 0px 0px 8px 8px;
    border-top: none;
    border: 1px solid #dfdfdf;
    background: #fff;
  }
  .profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fafaff;
    border: 1px solid #022438;
    p {
      color: #022438;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .role {
    /* gap: 10px; */
    span {
      color: #505050;
      font-size: 8px;
      font-weight: 400;
      line-height: 9px;
    }
  }
  .role-wrapper {
    gap: 10px;
  }
  .p-name {
    color: #6a6a6a;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 4px;
  }
  .p-email {
    color: #848484;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
  .nav-link {
    color: #6a6a6a;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding: 10px 20px !important;
  }
  .nav-pills .nav-link.active {
    color: #fff !important;
    background: #022438 !important;
    border-radius: 0 !important;
  }
  .card {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0;
    padding: 10px;
  }
  .card-body {
    padding: 22px 0px;
  }
  .form-group {
    margin-bottom: 15px;
    width: 100%;
    small {
      color: #ff5555;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .form-btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
  }
  .password-group {
    padding: 0px 10px 32px;
  }
  .password-group_title {
    color: #022438;
    font-size: 17px;
    font-weight: 400;
    line-height: 23px;
  }

  .btn-secondary-outline {
    border-radius: 8px !important;
  }
  .btn-primary {
    /* padding: 16px; */
    border-radius: 8px !important;
  }

  /* password */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .password-modal-container {
    display: flex;
    width: 100%;
    height: 100%;
    background: #fdfdff;
  }
  .inner-modal {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-top: 22px;
  }
  .password-card {
    display: grid;
    padding: 20px 16px;
    gap: 8px;
    width: 100%;
    background: #fdfdff;
  }
  .password-card_tittle {
    color: #022438;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .password-close {
    position: absolute;
    right: 0px;
  }

  /* preference */
  .pre-title {
    color: #022438;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    padding-bottom: 1rem;
    border-bottom: 1px solid #dfdfdf;
  }
  .pre-text {
    color: #9f9f9f;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-top: 9px;
  }
  .pre-group {
    gap: 12px;
  }
  .pre-box {
    display: grid;
    gap: 32px;
  }
  .pre-manage {
    display: flex;
    padding: 4px 10px;
    align-items: center;
    gap: 8px;
    border-radius: 26px;
    border: 1px solid #dfdfdf;
    background: #fff;
  }
  .pre-more {
    gap: 20px;
    margin-top: 20px;
  }
  .pre-select-btn {
    gap: 12px;
    flex-wrap: wrap;
  }
  .pre-role {
    gap: 10px;
    span {
      color: #505050;
      /* font-size: 8px; */
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .add-link-btn {
    width: 100%;
    border: 1px solid #848484;
    padding: 10px;
  }
  .select-option {
    width: 100%;
    border: 1px solid #848484;
    border-radius: 8px;
    padding: 10px;
  }
  .notify-svg {
    flex-basis: 10%;
  }

  .recurr-toggle {
    .switch {
      position: relative;
      display: inline-block;
      width: 38px;
      height: 21px;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border: 1px solid #cfcfcf;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 4px;
      bottom: 2.5px;
      background-color: #022438;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #022438;
      border: none;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #022438;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
      background-color: #fff;
      bottom: 3.5px;
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  @media (min-width: 780px) {
    .ps-20 {
      padding-left: 20px;
    }
    .profile--content {
      display: grid;
      width: 100%;
      grid-template-columns: 300px 1fr;
    }
    .profile--subtitle {
      font-size: 24px;
      line-height: 28px;
    }
    .profile--content_left {
      display: grid;
      padding: 40px 20px;
      gap: 23px;
      border-radius: 8px 0px 0px 8px;
      border: 1px solid #dfdfdf;
      background: #fdfdff;
    }
    .profile--content_left_top {
      flex-direction: column;
      gap: 23px;
      margin-bottom: 0;
    }
    .profile--content_right {
      border-radius: 0px 8px 8px 0px;
      border: 1px solid #dfdfdf;
      border-left: 0;
      background: #fff;
      max-width: 900px;
    }
    .profile-img {
      width: 65px;
      height: 65px;
      p {
        font-size: 24px;
        line-height: 40px;
      }
    }
    .p-name {
      text-align: center;
      font-size: 15px;
      line-height: 23px;
    }
    .p-email {
      text-align: center;
      font-size: 16px;
      line-height: 18px;
    }
    .card {
      padding: 27px 24px;
    }
    .card-body {
      padding: 0px;
    }
    .password-group {
      padding: 0px 28px 40px;
    }
    .nav {
      justify-content: flex-start !important;
      align-items: center;
      gap: 1rem !important;
      padding: 27px 0 0 24px;
    }
    .nav-pills .nav-link.active {
      color: #022438 !important;
      background: transparent !important;
      border-bottom: 3px solid #022438 !important;
    }
    .nav-link {
      border-bottom: 3px solid #fff !important;
      font-size: 20px;
      font-weight: 400;
      line-height: 23px;
      padding: 0 0 12px 0 !important;
    }
    .input-flex {
      display: flex;
      gap: 24px;
    }
    /* password */
    .overlay {
      align-items: center;
    }
    .password-modal-container {
      gap: 23px;
      width: auto;
      background: none;
    }
    .inner-modal {
      align-items: center;
      padding-top: 0px;
    }
    .password-card {
      display: grid;
      padding: 40px;
      gap: 16px;
      width: 580px;
      border-radius: 8px;
      background: #fdfdff;
    }
    .password-close {
      position: relative;
      top: -26%;
      left: 11px;
    }
    .password-card_tittle {
      font-size: 18px;
      line-height: 25px;
    }
    .role span {
      font-size: 10px;
      line-height: 14px;
    }
    .badge {
      padding: 6px 10px !important;
    }
    /* preference */
    .pre-box {
      display: grid;
      gap: 40px;
    }
    .pre-title {
      font-size: 20px;
      line-height: 23px;
    }
    /* .pre-role span {
    font-size: 12px;
    line-height: 14px;
  } */
    .pre-select-btn {
      flex-wrap: nowrap;
    }
    .add-link-btn {
      width: 250px;
    }
    .select-option {
      width: 250px;
    }
    .notify-svg {
      flex-basis: 1%;
    }
  }
</style>
