<template>
  <div class="checkout" v-if="loaded">
    <div class="row" v-if="cart.length > 0">
      <div class="col-12 col-md-8">
        <h6 class="text-muted">CHECKOUT</h6>
        <div class="card">
          <div
            class="card-header bg-white d-flex justify-content-between align-items-center"
          >
            <h6 class="head-txt mb-0">Delivery Location & Contact Phone Number</h6>
            <button
              class="head-txt btn btn-address"
              data-bs-toggle="modal"
              data-bs-target="#changeAddress"
            >
              CHANGE
            </button>
          </div>
          <div class="card-body">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                @change="selectAddress('company')"
                :checked="companyAddressChecked"
              />
              <label class="form-check-label w-100" for="flexRadioDefault1">
                <div class="company-detail">
                  <h6>{{ user.company }}</h6>
                  <p
                    class="text-muted"
                    v-if="
                      company.delivery_address && company.delivery_address != ''
                    "
                  >
                    {{ company.delivery_address }} {{ company.city.name }},
                    {{ company.state.state }}
                    <pre>{{ company.phone }}</pre>
                  </p>

                  <p class="alert alert-info py-2" v-else>
                    No company delivery address
                  </p>
                </div>
              </label>
            </div>
            <div class="form-check" v-if="delivery.city">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                @change="selectAddress('custom')"
                :checked="customAddressChecked"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                <div class="company-detail">
                  <p class="text-muted">
                    {{ delivery.address }} {{ delivery.city.name }},
                    {{ delivery.state.state }}
                  </p>
                  <p class="text-muted">{{ delivery.phone }}</p>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <h6 class="text-muted">ORDER SUMMARY</h6>
        <div class="card">
          <div class="card-header bg-white">
            <h6 class="head-txt mb-0">YOUR ORDER ({{ cart.length }} items)</h6>
          </div>
          <div class="card-body px-0">
            <div class="cart-item" v-for="(item, key) in cart" :key="key">
              <div class="cart-img">
                <img
                  :src="item.image"
                  alt="product image"
                  class="w-100 h-100"
                />
              </div>
              <div class="cart-content ms-1">
                <h6 class="cc-name">{{ item.name.substr(0, 24) }}...</h6>
                <span class="cc-price">{{ item.price }}</span>
                <p class="cc-qty text-muted">
                  Qty: <span>{{ item.quantity }}</span>
                </p>
                <p class="cc-qty text-muted">
                  Shiping estimate:
                  <span class="color-cart"> ₦{{ item.delivery_fee }}</span>
                </p>
              </div>
            </div>
            <div class="calculation px-2">
              <div
                class="subtotal d-flex justify-content-between align-items-center mt-2"
              >
                <p class="mb-0">Subtotal</p>
                <h6 class="mb-0">₦{{ formatPrice(calculateSubTotal) }}</h6>
              </div>
              <div
                class="subtotal d-flex justify-content-between align-items-center mt-2 pb-2"
                style="border-bottom: 1px solid #ededed"
              >
                <p class="mb-0">Shipping estimate</p>
                <h6 class="mb-0">₦{{ formatPrice(calculateDelivery) }}</h6>
              </div>
              <div
                class="subtotal d-flex justify-content-between align-items-center mt-2"
              >
                <b class="mb-0">Total</b>
                <h6 class="mb-0">
                  ₦{{ formatPrice(calculateSubTotal + calculateDelivery) }}
                </h6>
              </div>
              <div class="submit mt-2">
                <button
                  class="btn k-btn w-100 py-2"
                  @click="submitForApproval()"
                >
                  <span v-if="!loading">Submit for approval</span>
                  <span v-else>Loading...</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="changeAddress"
        tabindex="-1"
        aria-labelledby="changeAddressLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title">New Delivery Address</h6>
              <button
                type="button"
                class="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="changeAddress()">
                <!-- <div class="form-group">
                  <label for="">Phone</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="delivery.phone"
                  />
                </div> -->
                <div class="form-group">
                  <label for="">State/Region</label>
                  <multiselect
                    v-model="delivery.state"
                    :options="states"
                    label="state"
                    @select="getCities($event)"
                  >
                  </multiselect>
                </div>
                <div class="form-group">
                  <label for="">Cities</label>
                  <multiselect
                    v-model="delivery.city"
                    :options="cities"
                    label="name"
                  >
                  </multiselect>
                </div>
                <div class="form-group">
                  <label for="">Phone</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="delivery.phone"
                  />
                </div>

                <div class="form-group">
                  <label for="">Delivery address</label>
                  <textarea
                    class="form-control"
                    id=""
                    cols="30"
                    rows="5"
                    v-model="delivery.address"
                  ></textarea>
                </div>
                <button class="btn k-btn w-100" type="submit">
                  <span v-if="!loading"> Use Address</span>
                  <span v-else>Loading...</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="alert alert-info text-center">
      <p>Cart is empty</p>
      <router-link to="/jumia" class="btn k-btn">Start Shopping</router-link>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { mapState } from "vuex";
export default {
  components: { Multiselect },
  computed: {
    ...mapState({
      user: (context) => context.user,
      cart: (context) => context.cart['xerox'],
    }),
    calculateDelivery: function () {
      var vm = this;
      const total = this.cart.reduce(function (start, item) {
        console.log(item)
        console.log(item.delivery_fee)
        return start + parseFloat(vm.parsePrice(item.delivery_fee));
      }, 0);
      // console.log(total);
      return total;
    },
    calculateSubTotal: function () {
      var vm = this;
      const total = this.cart.reduce(function (start, item) {
        return start + parseFloat(vm.parsePrice(item.price)) * item.quantity;
      }, 0);
      return total;
    },
  },
  data() {
    return {
      checked: true,
      companyAddressChecked: true,
      customAddressChecked: false,
      loaded: false,
      loading: false,
      states: [],
      phone: "",
      cities: [],
      // city: "",
      delivery_address: "",
      delivery: {
        city: "",
        address: "",
        phone: "",
        state: { id: 1061493610, state: "Lagos" },
      },
      address: {
        city: "",
        address: "",
        state: { id: 1061493610, state: "Lagos" },
        phone: "",
      },
      company: {},
      // cart: [],
    };
  },
  methods: {
    selectAddress(type) {
      if (type == "company") {
        this.address = {
          city: this.company.city,
          address: this.company.delivery_address,
          state: this.company.state,
        };
        this.companyAddressChecked = true;
        this.customAddressChecked = false;
      } else {
        this.address = {
          city: this.delivery.city,
          address: this.delivery.address,
          state: this.delivery.state,
        };
        this.customAddressChecked = true;
        this.companyAddressChecked = false;
      }
      this.getDeliveryPrices(this.address.city);
    },
    changeAddress() {
      console.log(this.delivery)

      if(this.delivery.city == ''){
        window.ToasterAlert("error", "Delivery city field is required");
        return false
      }
      if(this.delivery.address == ''){
        window.ToasterAlert("error", "Delivery address field is required");
        return false
      }
      this.address = this.delivery;
      this.getDeliveryPrices();
      window.$("#changeAddress").modal("hide");
      this.customAddressChecked = true;
      this.companyAddressChecked = false;
    },
    submitForApproval() {
      var orders = [];
      if (this.address.city.id == "") {
        window.ToasterAlert("error", "Invalid delivery address");
        return false;
      }
      this.cart.map((ord) => {
        var d = {
          link: ord.link,
          quantity: ord.quantity,
        };
        if (ord.variations.length > 0) {
          d.variant = ord.variations[0].name;
        }
        orders.push(d);
      });
      // console.log(this.address)
      var hold = {
        orders,
        // address: `${this.address.address}, ${this.address.city.name}, ${this.address.state.state}`,
        address: this.address.address,
        region_id: this.address.state.id.toString(),
        city_id: this.address.city.id.toString(),
        email: this.user.email,
        phone: this.delivery.phone,
      };
      // console.log(hold);
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", {
          endpoint: "user/xerox/orders",
          details: hold,
        })
        .then((resp) => {
          this.$store.commit("setLoader", false);
          window.ToasterAlert("success", "Order submitted successfully");
          this.$store.commit("setStateCart",  {data:[], merchant:'xerox'});
          window.location = `/order/${resp.data}`
        });
    },
    async getCities(event) {
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("get", `konga/getcities/${event.id}`)
        .then((resp) => {
          this.$store.commit("setLoader", false);
          this.cities = resp.data;
          console.log(resp);
        });
      
    },
    getDeliveryPrices() {
      this.cart.map(async (item) => {
        item.delivery_fee = 450;
      });
    },
    async getCompanyProfile() {
      await this.$store
        .dispatch(
          "get",
          "users/companyprofile/read/"
        )
        .then((resp) => {
          this.company = resp.data[0];
          this.company.state = JSON.parse(this.company.state);
          this.company.city = JSON.parse(this.company.city);
          this.delivery.phone = this.$store.state.user.user_phone;
        });
    },
    async getStates() {
      await this.$store.dispatch("get", "konga/getstates").then((resp) => {
        this.states = resp.data;
        this.loaded=true
      });
    },
  },
  async created() {
    this.getDeliveryPrices();
    console.log(this.cart)
    await this.getCompanyProfile();
    if (this.cart.length < 1) {
      window.ToasterAlert("error", "Cart is empty");
      this.$router.push("/xerox");
    }
    await this.getStates();
    await this.getCities(this.delivery.state);

    

    if (this.company.city && this.company.city.id) {
      
      this.address = {
        city: this.company.city,
        address: this.company.delivery_address,
        state: this.company.state,
      };
    } else {
      this.delivery.state = this.states[0];
      this.delivery.city = this.cities[0];
    }
  },
};
</script>

<style scoped lang="scss">
.checkout {
  .subtotal {
    flex-wrap: wrap;
    p {
      font-size: 14px;
    }
  }
  .head-txt {
    font-size: 14px;
  }
  .cc-name {
    font-size: 13px;
    margin-bottom: 0;
    white-space: nowrap;
  }
  .cc-qty {
    font-size: 13px;
    margin-bottom: 0;
    span {
      font-weight: bold;
    }
  }
  .cc-price {
    font-size: 13px;
    color: #ec037f;
  }
  .cart-item {
    display: flex;
    border-bottom: 1px solid #ededed;
    padding: 10px 0;
    .cart-img {
      width: 5em;
      img {
        object-fit: contain;
      }
    }
  }
  .btn-address {
    color: #ec037f;
  }
}
</style>
