<template>
  <div class="cart" v-if="loaded">
    <div class="row">
      <div class="col-12 col-md-9">
        <div class="card">
          <div class="card-header bg-white">
            <h6 class="card-title mb-0">Edit Order</h6>
          </div>
          <div class="card-body pt-0">
            <div class="cart-items">
              <div
                class="cart-item"
                v-for="(item, key) in order.product"
                :key="key"
              >
                <div class="ci-top">
                  <div class="ci-left">
                    <div class="ci-img">
                      <img
                        :src="item.image"
                        alt="product image"
                        class="w-100 h-100"
                      />
                    </div>
                    <div class="ci-content">
                      <h6 class="ci-prod-name mb-0">
                        {{ item.description }}
                      </h6>
                      <h6 class="ci-prod-name mb-0">
                        Shipping: {{ item.shipping }}
                      </h6>
                      <h6 class="ci-prod-name mb-0">Tax: {{ item.tax }}</h6>
                    </div>
                  </div>
                  <div class="ci-right">
                    <h5 class="ci-price">
                      ₦{{ formatPrice(item.price * item.quantity) }}
                    </h5>
                  </div>
                </div>
                <div class="ci-bottom">
                  <div class="ci-remove">
                    <button class="btn btn-remove" @click="removeProduct(item)">
                      <i class="fa fa-trash"></i>
                      REMOVE
                    </button>
                  </div>
                  <div class="add-to-cart">
                    <button
                      class="btn btn-purple"
                      :disabled="item.quantity == 1"
                      @click="reduceOrRemoveFromProduct(item)"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                    <div class="cart-input">{{ item.quantity }}</div>
                    <button class="btn btn-purple" @click="addQuantity(item)">
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div class="card">
          <div class="card-header bg-white">
            <h6 class="card-title mb-0">Order summary</h6>
          </div>
          <div class="card-body pb-2">
            <div
              class="subtotal d-flex justify-content-between align-items-center mb-2"
            >
              <p class="mb-0">Subtotal</p>
              <h5 class="mb-0">₦{{ formatPrice(calculateSubTotal) }}</h5>
            </div>
            <div
              class="subtotal d-flex justify-content-between align-items-center mb-2"
            >
              <p class="mb-0">Shipping</p>
              <h5 class="mb-0">₦{{ formatPrice(calculateShipping) }}</h5>
            </div>
            <div
              class="subtotal d-flex justify-content-between align-items-center mb-2"
            >
              <p class="mb-0">Tax</p>
              <h5 class="mb-0">₦{{ formatPrice(calculateTax) }}</h5>
            </div>
          </div>
          <div class="card-footer bg-white">
            <button
              class="btn btn-purple w-100"
              :disabled="loading"
              @click.prevent="updateOrder()"
            >
              <span v-if="!loading">Update Order</span>
              <span v-else>Updating...</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      cart: (context) => context.cart,
    }),
    calculateSubTotal: function () {
      const total = this.order.product.reduce(function (start, item) {
        return start + item.price * item.quantity;
      }, 0);
      return total;
    },
    calculateShipping: function () {
      const total = this.order.product.reduce(function (start, item) {
        return start + item.shipping;
      }, 0);
      return total;
    },
    calculateTax: function () {
      const total = this.order.product.reduce(function (start, item) {
        return start + item.tax;
      }, 0);
      return total;
    },
  },
  data() {
    return {
      order: {},
      loaded: false,
      loading: false,
    };
  },
  methods: {
    getOrder() {
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch(
          "get",
          `users/orderproducts/${this.$route.params.id}`
        )
        .then((resp) => {
          console.log(resp);
          this.$store.commit("setLoader", false);
          this.loaded = true;
          this.order = resp.data;
        });
    },
    updateOrder() {
      var details = this.order.product.map((item) => {
        return {
          id: item.product_id,
          quantity: item.quantity,
        };
      });
      console.log(details);
      this.loading = true;
      this.$store
        .dispatch("post", {
          endpoint: "users/edit/order/",
          details: { products: details, order_id: this.$route.params.id },
        })
        .then(() => {
          this.loading = false;
          window.ToasterAlert("success", "Order updated successfully");
          this.$router.push("/order/" + this.$route.params.id);
          // console.log(resp);
        });
    },
    addQuantity(product) {
      this.order.product.map((item) => {
        if (item.product_id == product.product_id) {
          item.quantity += 1;
        }
      });
    },
    reduceOrRemoveFromProduct(product) {
      this.order.product.map((item) => {
        if (item.product_id == product.product_id) {
          item.quantity -= 1;
        }
      });
    },
    removeProduct(product) {
      this.order.product.splice(
        this.order.product.findIndex(
          (item) => item.product_id == product.product_id
        ),
        1
      );
    },
  },
  created() {
    this.getOrder();
    // console.log(this.cart);
  },
};
</script>

<style scoped lang="scss">
.cart {
  .subtotal {
    flex-wrap: wrap;
    p {
      font-size: 14px;
    }
    h5 {
      font-size: 18px;
    }
  }
  .sub-note {
    font-size: 12px;
    color: #75757a;
  }
  .cart-items {
    .ci-price {
      font-size: 20px;
    }
    .cart-item {
      border-bottom: 1px solid #ededed;
      padding-bottom: 10px;
      margin-top: 20px;
      .ci-top {
        display: flex;
        justify-content: space-between;
        // padding: 10px 15px;
        .ci-left {
          display: flex;
          .ci-img {
            width: 6em;
            height: 5em;
            img {
              object-fit: contain;
            }
          }
          .ci-content {
            margin-left: 7px;
            .ci-prod-name {
              font-weight: normal;
              font-size: 14px;
            }
            span {
              font-size: 14px;
              font-weight: 300;
            }
          }
        }
      }
      .ci-bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        align-items: center;
        .add-to-cart {
          .btn-purple i {
            font-size: 14px;
          }
        }
        .btn-remove {
          color: #5f63f2;
          margin-left: -8px;
          border-radius: 3px;
          font-size: 13px;
          i {
            color: #5f63f2;
            margin-right: 15px;
          }
          &:hover {
            background-color: #5f63f245;
            font-weight: 500;
          }
        }
      }
    }
  }
  .card-footer a {
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .cart .cart-items .cart-item .ci-top {
    flex-wrap: wrap;
  }
  .card {
    margin-top: 10px;
  }
}
</style>
