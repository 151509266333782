<template>
  <div class="manage" v-if="loaded">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <div class="main-title">
        <h6 class="mb-0">Team</h6>
        <div class="v-line"></div>
        <div class="search">
          <input type="text" class="form-control search" placeholder="Search users" v-model="search" />
        </div>
      </div>

      <div class="manage-top-btns">
        <button class="btn btn-primary btn-add" data-bs-toggle="modal" data-bs-target="#manageAccess">Add Team Member</button>

        <button class="btn btn-success btn-add ms-2" @click="exportToExcel()" :disabled="usersExportLoading">
          <i class="fas fa-file-excel me-2"></i>
          <span v-if="!usersExportLoading">Export Excel</span>
          <span v-else>Loading...</span>
        </button>
      </div>
    </div>
    <div class="manage-body mt-3">
      <div class="card b-card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="px-0"></th>
                  <th class="td-center">
                    Requester
                    <i v-popover:requester class="fa fa-info-circle cursor-pointer p-color"></i>
                    <popover name="requester">
                      Everyone within your business that can make a request for products and services. The end-users of internal business operations purchases.
                    </popover>
                  </th>
                  <th class="td-center">
                    Endorser
                    <i class="fa fa-info-circle cursor-pointer p-color" v-popover:endorser></i>
                    <popover name="endorser"> Can view, edit, decline or endorse purchase requests. Is not a final approver. </popover>
                  </th>
                  <th class="td-center">
                    Approver
                    <i class="fa fa-info-circle cursor-pointer p-color" v-popover:approver></i>
                    <popover name="approver"> Team member with permission to view, edit, decline or approve purchase requests from within your business. </popover>
                  </th>
                  <th class="td-center">
                    Tech
                    <i class="fa fa-info-circle cursor-pointer p-color" v-popover:tech></i>
                    <popover name="tech"> Team member can create and manage business profile, team member, groups and integrations with third-party services. </popover>
                  </th>
                  <th class="td-center">
                    Finance
                    <i class="fa fa-info-circle cursor-pointer p-color" v-popover:finance></i>
                    <popover name="finance">
                      Finance profile owners can view purchases and transactions history, add funds to the company wallet and generate expense reports.
                    </popover>
                  </th>
                  <!-- <th class="td-center">
                    Finance Analyst
                    <i
                      class="fa fa-info-circle cursor-pointer p-color"
                      v-popover:analyst
                    ></i>
                    <popover name="analyst">
                      Finance analyst can create payee and request payment.
                    </popover>
                  </th> -->
                  <th class="td-center">
                    Supplier
                    <i class="fa fa-info-circle cursor-pointer p-color" v-popover:supplier></i>
                    <popover name="supplier"> Supplier can create request payments. </popover>
                  </th>

                  <th class="td-center">
                    Admin
                    <i class="fa fa-info-circle cursor-pointer p-color" v-popover:admin></i>
                    <popover name="admin"> Unrestricted access to all aspects of the dashboard including creating and managing your business account. </popover>
                  </th>
                  <th class="td-center">Action</th>
                </tr>
              </thead>
              <tbody v-if="users.users_result.length > 0">
                <tr v-for="(user, index) in filteredList" :key="index">
                  <td class="px-0 td-sd">
                    <div class="user-info">
                      <!-- <figure>
                        <img
                          src="@/assets/img/user1.png"
                          alt=""
                          class="w-100"
                        />
                      </figure> -->
                      <div class="user-details">
                        <h6>{{ user.first_name }} {{ user.last_name }}</h6>
                        <span class="text-muted d-flex"
                          >{{ user.email }}
                          <div class="ms-2" v-if="user.pending">
                            <i class="fa fa-info-circle cursor-pointer p-color" v-popover="{ name: 'pending' + index }"></i>
                            <popover :name="'pending' + index"> Access level of user awaiting registration cannot be changed </popover>
                          </div>
                        </span>
                      </div>
                    </div>
                  </td>

                  <td class="td-center">
                    <input type="checkbox" :checked="havePermission(user, 4)" @change="updateRole(user, 4)" :disabled="user.pending ? disabledCheck : !disabledCheck" />
                  </td>
                  <td class="td-center">
                    <input type="checkbox" :checked="havePermission(user, 54)" @change="updateRole(user, 54)" :disabled="user.pending ? disabledCheck : !disabledCheck" />
                  </td>
                  <td class="td-center">
                    <input type="checkbox" :checked="havePermission(user, 14)" @change="updateRole(user, 14)" :disabled="user.pending ? disabledCheck : !disabledCheck" />
                  </td>
                  <td class="td-center">
                    <input type="checkbox" :checked="havePermission(user, 34)" @change="updateRole(user, 34)" :disabled="user.pending ? disabledCheck : !disabledCheck" />
                  </td>
                  <td class="td-center">
                    <input type="checkbox" :checked="havePermission(user, 24)" @change="updateRole(user, 24)" :disabled="user.pending ? disabledCheck : !disabledCheck" />
                  </td>
                  <!-- <td class="td-center">
                    <input
                      type="checkbox"
                      :checked="havePermission(user, 74)"
                      @change="updateRole(user, 74)"
                      :disabled="user.pending ? disabledCheck : !disabledCheck"
                    />
                  </td> -->
                  <td class="td-center">
                    <input type="checkbox" :checked="havePermission(user, 84)" @change="updateRole(user, 84)" :disabled="user.pending ? disabledCheck : !disabledCheck" />
                  </td>
                  <td class="td-center">
                    <input type="checkbox" :checked="havePermission(user, 44)" @change="updateRole(user, 44)" :disabled="user.pending ? disabledCheck : !disabledCheck" />
                  </td>
                  <td class="td-center user-action">
                    <router-link :to="'/user/' + user.user_id" class="btn btn-view" v-if="!user.pending">
                      <i class="fa fa-user-edit"></i>
                    </router-link>
                    <div class="d-flex" v-if="user.pending">
                      <button class="btn btn-view f-13" @click="resendMail(user)">
                        <i class="fa fa-redo"></i>
                      </button>
                      <!-- <button
                        class="btn btn-view f-13 ms-1"
                        @click="cancelRequest(user.id)"
                      >
                        <i class="fa fa-trash-alt"></i>
                      </button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
              <NoData :data="{ colspan: 7, message: 'No available user' }" v-else></NoData>
            </table>
          </div>
          <nav aria-label="Page navigation example" v-if="users.total > users.records_per_page">
            <ul class="pagination justify-content-end">
              <li class="page-item" v-if="pagination.current != 1" :class="pagination.current == 1 ? 'disabled' : ''">
                <a class="page-link" href="#" @click.prevent="getUsers(pagination.current - 1)" tabindex="-1" aria-disabled="true">Previous</a>
              </li>
              <li class="page-item" v-for="(item, index) in pagination.pagLimit" :key="index" :class="item == pagination.current ? 'active' : ''">
                <a v-if="item <= pagination.last" class="page-link" @click.prevent="getUsers(item)" href="#">{{ item }}</a>
              </li>
              <li class="page-item" v-if="pagination.last > pagination.pagLimit">
                <select @change="nextPage($event)" class="select-page-link" :class="pagination.current > pagination.pagLimit ? 'active' : ''">
                  <option value="">...</option>
                  <option :value="item" v-for="(item, index) in pagination.last - pagination.pagLimit" :key="index">
                    {{ item + pagination.pagLimit }}
                  </option>
                </select>
              </li>
              <li class="page-item" v-if="pagination.current != pagination.last">
                <a class="page-link" @click="getUsers(pagination.current + 1)" href="#">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="modal fade" id="manageAccess" tabindex="-1" aria-labelledby="manageAccessLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="manageAccessLabel">Add Team Member</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addUser()" class="form">
              <div class="form-group">
                <label for="email" class="mb-2">Email</label>
                <input type="email" id="email" class="form-control" v-model="user.email" />
              </div>
              <div class="form-group mt-2">
                <label for="" class="d-block mb-1">Roles</label>
                <!-- <select name="" class="form-control" v-model="user.role_id">
                  <option v-for="role in roles" :key="role.id" :value="role.id">
                    {{ role.role_name }}
                  </option>
                </select> -->
                <div class="row ms-1">
                  <div class="form-check form-check-inline col-sm-3" v-for="role in roles" :key="role.id">
                    <input class="form-check-input" type="radio" name="profile" :id="'profile' + role.id" :value="role.id" v-model="user.role_id" />
                    <label class="form-check-label" :for="'profile' + role.id">{{ role.role_name }}</label>
                  </div>
                </div>
              </div>
              <div class="submit mt-3">
                <button class="btn btn-primary px-5 f-13" type="submit">Add to Team</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoData from '@/components/dashboard/noData.vue';
import axios from 'axios';
export default {
  components: { NoData },
  computed: {
    filteredList() {
      return this.users.users_result.filter((user) => {
        user.first_name = user.first_name ?? '';
        user.last_name = user.last_name ?? '';
        return (
          user.first_name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.last_name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.email.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      user: {
        email: '',
        role_id: '4',
      },
      roles: [],
      users: [],
      loaded: false,
      search: '',
      usersExportLoading: false,
      disabledCheck: true,
      pagination: {
        last: 2,
        current: 1,
        pagLimit: 6,
      },
    };
  },
  methods: {
    cancelRequest(id) {
      console.log(id);
    },
    resendMail(user) {
      console.log(user);
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: `users/createusermail`,
          details: { email: user.email },
        })
        .then(() => {
          this.$store.commit('setLoader', false);
          window.ToasterAlert('success', 'Invite email sent');
        })
        .catch(() => {
          this.$store.commit('setLoader', false);
        });
    },
    exportToExcel() {
      this.usersExportLoading = true;
      axios
        .get(this.$store.state.endpoint + 'company/report/allusers', {
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + this.$store.state.token,
          },
        })
        .then((res) => {
          this.usersExportLoading = false;
          var FILE = window.URL.createObjectURL(new Blob([res.data]));

          var docUrl = document.createElement('a');
          docUrl.href = FILE;
          docUrl.setAttribute('download', 'users.xlsx');
          window.document.body.appendChild(docUrl);
          docUrl.click();
        });
    },
    havePermission(user, value) {
      const permit = this.parsePermissions(user.role_id);
      return permit.some((item) => {
        return item == value;
      });
    },
    addUser() {
      if (this.user.email.length < 1) {
        window.SweetAlert('error', 'Team Member email is required');
        return false;
      }
      window.$('#manageAccess').modal('hide');
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: 'users/create/',
          details: this.user,
        })
        .then(() => {
          this.user.email = '';
          this.getUsers();
          this.$store.commit('setLoader', false);
          window.SweetAlert('success', 'Invite sent to team member');
        })
        .catch((err) => {
          this.$store.commit('setLoader', false);
          this.$store.dispatch('handleError', err);
        });
    },
    updateRole(user, role) {
      var roles = this.parsePermissions(user.role_id);
      var check = roles.find((item) => {
        return item == role;
      });
      if (check) {
        roles.splice(
          roles.findIndex((item) => item == role),
          1
        );
      } else {
        roles.push(role);
      }

      const permit = roles.join(',');
      this.users.users_result.forEach((item) => {
        if (item.user_id == user.user_id) {
          item.role_id = permit;
        }
      });
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('post', {
          endpoint: 'users/approveusers',
          details: {
            user_id: user.user_id,
            role_id: permit,
          },
        })
        .then(() => {
          this.$store.commit('setLoader', false);

          window.window.SweetAlert('success', 'Team Member role updated successfully');
        })
        .catch((err) => {
          this.$store.commit('setLoader', false);
          this.$store.dispatch('handleError', err);
        });
    },
    nextPage(no) {
      var cal = Number(no.target.value) + Number(this.pagination.pagLimit);
      this.getUsers(cal);
    },
    getUsers(page = 1) {
      this.$store.commit('setLoader', true);
      this.$store.dispatch('get', `users/company/all?page=${page}`).then((resp) => {
        console.log(resp);
        this.$store.commit('setLoader', false);
        this.users = resp.data;
        this.pagination.current = page;
        this.pagination.last = Math.ceil(this.users.total / this.users.records_per_page);
        this.loaded = true;
      });
    },
    getRoles() {
      this.$store.dispatch('get', 'users/roles').then((resp) => {
        this.roles = resp.data;
      });
    },
  },
  mounted() {},
  created() {
    this.getUsers();
    this.getRoles();
  },
};
</script>

<style>
.vue-popover {
  top: auto !important;
  left: auto !important;
}
</style>
<style scoped lang="scss">
.user-action {
  .btn-view {
    color: #012538 !important;
  }
}
.user-info {
  display: flex;
  align-items: center;
  figure {
    width: 35px;
    margin: 0 7px 0 0;
  }
  .user-details {
    h6 {
      font-size: 14px;
      margin-bottom: 0;
      text-transform: capitalize;
    }
    span {
      font-size: 12px;
    }
  }
}
table thead tr th {
  color: #5a5f7d;
  background: #f8f9fb;
}
.btn-add {
  font-size: 14px;
  border-radius: 0;
}
.btn-close {
  font-size: 14px;
}
.main-title .v-line {
  border: 1px solid #ced4da;
  background: #ced4da;
  height: 36px;
  width: 0.1px;
  margin: 0 15px;
}
.search {
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  height: 40px;
  width: 20em;
}
.t-badge .badge {
  margin-right: 3px;
}
.manage .form-group {
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .manage-top-btns {
    margin-top: 10px;
  }
  .main-title .v-line {
    margin: 0 12px;
  }
  .search,
  .main-title {
    width: 100%;
  }
}
</style>
