<template>
  <div class="manage" v-if="loaded">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="card-title">Join Request</h4>
    </div>
    <div class="manage-body mt-2">
      <div class="card b-card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody v-if="users.length > 0">
                <tr v-for="(user, index) in users" :key="index">
                  <td>{{ user.email }}</td>
                  <td class="req-actions text-center">
                    <button
                      class="btn btn-view f-14"
                      data-bs-toggle="modal"
                      data-bs-target="#approveUser"
                      @click="selectedUser = user"
                    >
                      <i class="fa fa-user-check"></i>
                    </button>
                    <button
                      class="btn btn-view f-14 ms-2"
                      data-bs-toggle="modal"
                      data-bs-target="#cancelUser"
                      @click="selectedUser = user"
                    >
                      <i class="fa fa-user-times"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              <NoData
                v-else
                :data="{ colspan: 3, message: 'No pending requests' }"
              ></NoData>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="approveUser"
      tabindex="-1"
      aria-labelledby="approveUserLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="card-title mb-0" id="approveUserLabel">Select Role</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="approveUser()" class="form">
              <div class="form-group">
                <label for="" class="f-14"> Email </label>
                <input
                  type="email"
                  disabled
                  class="form-control f-15"
                  v-model="selectedUser.email"
                />
              </div>
              <div class="form-group">
                <label for="" class="f-14">Role</label>
                <select
                  name=""
                  class="form-control f-15"
                  v-model="selectedUser.role_id"
                >
                  <option v-for="role in roles" :key="role.id" :value="role.id">
                    {{ role.role_name }}
                  </option>
                </select>
              </div>
              <div class="submit mt-4">
                <button class="btn btn-primary w-100 f-15 py-2" type="submit">
                  Approve User
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="cancelUser"
      tabindex="-1"
      aria-labelledby="cancelUserLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <!-- <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div> -->
          <div class="d-flex justify-content-end p-2">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body py-5">
            <div class="d-flex justify-content-center cancR">
              <i class="fa fa-info-circle"></i>
              <p class="my-4 f-15">Confirm request cancellation</p>
              <button class="btn btn-success f-14" @click="cancelUser()">
                Cancel Request
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoData from "@/components/dashboard/noData.vue";
export default {
  components: { NoData },
  data() {
    return {
      users: [],
      loaded: false,
      roles: [],
      selectedUser: {},
    };
  },
  methods: {
    getUsers() {
      this.$store
        .dispatch("get", "users/approveusers")
        .then((resp) => {
          // console.log(resp);
          if (resp.data.length != 0) {
            if (resp.data.approve_result.length > 0) {
              this.users = resp.data.approve_result;
            } else {
              this.users = [];
            }
          }
          this.loaded = true;
        });
    },
    approveUser() {
      window.$("#approveUser").modal("hide");
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", {
          endpoint: "users/approveusers",
          details: {
            user_id: this.selectedUser.id,
            role_id: this.selectedUser.role_id,
          },
        })
        .then((resp) => {
          console.log(resp.data);
          this.$store.commit("setLoader", false);
          window.SweetAlert("success", "User added successfully");
          this.users.splice(
            this.users.findIndex((item) => item.id == this.selectedUser.id),
            1
          );
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          this.$store.dispatch("handleError", err);
        });
    },
    cancelUser() {
      window.$("#cancelUser").modal("hide");
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", {
          endpoint: "users/rejectusers",
          details: {
            user_id: this.selectedUser.id,
          },
        })
        .then((resp) => {
          console.log(resp.data);
          this.$store.commit("setLoader", false);
          window.SweetAlert("success", "User request cancelled successfully");
          this.users.splice(
            this.users.findIndex((item) => item.id == this.selectedUser.id),
            1
          );
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          this.$store.dispatch("handleError", err);
        });
    },
    getRoles() {
      this.$store.dispatch("get", "users/roles").then((resp) => {
        this.roles = resp.data;
      });
    },
  },
  created() {
    this.getUsers();
    this.getRoles();
  },
};
</script>

<style scoped lang="scss">
.cancR {
  flex-direction: column;
  align-items: center;
  i {
    font-size: 60px;
    color: #dc3545;
  }
}
label {
  margin-bottom: 5px;
}

.req-actions .btn-view {
  color: black;
  font-size: 20px;
}

.cancv {
  padding: 5px 10px;
}
.table .btn {
  border: none;
}
</style>
