import axios from 'axios';
// import router from "@/router";

export default {
  handleError(context, error) {
    context.commit('setLoader', false);
    if (error.request.status == 422) {
      var resp = JSON.parse(error.request.response);
      var err = resp.errors;
      var msg = '';
      for (var item in err) {
        msg = err[item][0];
        break; // it picks the first error ;
      }
      window.ToasterAlert('error', msg);
      // return msg;
    } else if (error.request.status == 403) {
      window.ToasterAlert('error', error.request.response);
      if (error.request.response.includes('jwt')) {
        context.commit('logout');
      }
    } else if (error.request.status == 409) {
      console.log(error.request);
      console.log(error.request.response);
      err = JSON.parse(error.request.response);
      window.ToasterAlert('error', err.message);
    } else if (error.request.status == 404) {
      resp = JSON.parse(error.request.response);
      msg = 'Request not found';
      window.ToasterAlert('error', msg);
    } else if (error.request.status == 400) {
      resp = JSON.parse(error.request.response);
      err = resp.errors;
      msg = err[0].msg + ': ' + err[0].param.replace('_', ' ');
      window.ToasterAlert('error', msg);
    } else if (error.request.status == 401) {
      window.ToasterAlert('error', error.request.response);
      // context.commit('logout');
    } else {
      window.ToasterAlert('error', "Something went wrong, but don't fret. Please try again");
    }
  },
  post(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${context.state.endpoint}${data.endpoint}`, data.details, {
          headers: {
            Authorization: 'Bearer ' + context.state.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          // context.commit("setLoader", false);
          context.dispatch('handleError', error);
          reject(error);
        });
    });
  },
  post2(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${context.state.endpoint2}${data.endpoint}`, data.details, {
          headers: {
            Authorization: 'Bearer ' + context.state.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          // context.commit("setLoader", false);
          context.dispatch('handleError', error);
          reject(error);
        });
    });
  },

  get(context, endpoint) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.state.endpoint + endpoint, {
          headers: {
            Authorization: 'Bearer ' + context.state.token,
            Accept: 'application/json',
          },
        })
        .then((data) => {
          context.commit('setLoader', false);
          resolve(data);
        })
        .catch((error) => {
          context.commit('setLoader', false);
          context.dispatch('handleError', error);
          reject(error);
        });
    });
  },
  put(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(context.state.endpoint + data.endpoint, data.details, {
          headers: {
            Authorization: 'Bearer ' + context.state.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((data) => {
          context.commit('setLoader', false);
          resolve(data);
        })
        .catch((error) => {
          context.commit('setLoader', false);
          context.dispatch('handleError', error);
          reject(error);
        });
    });
  },
  delete(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(context.state.endpoint + data.endpoint, data.details, {
          headers: {
            Authorization: 'Bearer ' + context.state.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((data) => {
          context.commit('setLoader', false);
          resolve(data);
        })
        .catch((error) => {
          context.commit('setLoader', false);
          context.dispatch('handleError', error);
          reject(error);
        });
    });
  },
  getUser(context, user_id = null) {
    // console.log(context.state.user);
    var id = context.state.user.user_id;
    if (user_id) {
      id = user_id;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(context.state.endpoint + 'users/verify/' + id, {
          headers: {
            Authorization: 'Bearer ' + context.state.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((data) => {
          context.commit('setLoader', false);
          resolve(data);
        })
        .catch((error) => {
          context.commit('setLoader', false);
          context.dispatch('handleError', error);
          reject(error);
        });
    });
  },
};
