<template>
  <section class="transactions manage" v-if="loaded">
    <div class="d-flex justify-content-between align-items-center tranc-header">
      <div class="tp-setting">
        <div class="appr-req">
          <span>Finance</span>
          <h6 class="mb-0">{{ expenseFilter ? filter.expense.title : '' }} Transactions</h6>
        </div>
      </div>

      <div class="d-flex align-items-center gap-2">
        <button class="btn btn-darkBlue btn-add f-14" @click="exportToExcel()">
          <span v-if="!transactionsExportLoading">Export</span>
          <span v-else>Loading...</span>
        </button>
        <button class="btn btn-outline btn-add f-14 d-flex gap-1 align-items-center" v-if="!showFilter && !expenseFilter" @click.prevent="toggleFilter()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M18.4375 6.5625H1.5625C1.31386 6.5625 1.0754 6.46373 0.899587 6.28791C0.723772 6.1121 0.625 5.87364 0.625 5.625C0.625 5.37636 0.723772 5.1379 0.899587 4.96209C1.0754 4.78627 1.31386 4.6875 1.5625 4.6875H18.4375C18.6861 4.6875 18.9246 4.78627 19.1004 4.96209C19.2762 5.1379 19.375 5.37636 19.375 5.625C19.375 5.87364 19.2762 6.1121 19.1004 6.28791C18.9246 6.46373 18.6861 6.5625 18.4375 6.5625ZM15.3125 10.9375H4.6875C4.43886 10.9375 4.2004 10.8387 4.02459 10.6629C3.84877 10.4871 3.75 10.2486 3.75 10C3.75 9.75136 3.84877 9.5129 4.02459 9.33709C4.2004 9.16127 4.43886 9.0625 4.6875 9.0625H15.3125C15.5611 9.0625 15.7996 9.16127 15.9754 9.33709C16.1512 9.5129 16.25 9.75136 16.25 10C16.25 10.2486 16.1512 10.4871 15.9754 10.6629C15.7996 10.8387 15.5611 10.9375 15.3125 10.9375ZM11.5625 15.3125H8.4375C8.18886 15.3125 7.9504 15.2137 7.77459 15.0379C7.59877 14.8621 7.5 14.6236 7.5 14.375C7.5 14.1264 7.59877 13.8879 7.77459 13.7121C7.9504 13.5363 8.18886 13.4375 8.4375 13.4375H11.5625C11.8111 13.4375 12.0496 13.5363 12.2254 13.7121C12.4012 13.8879 12.5 14.1264 12.5 14.375C12.5 14.6236 12.4012 14.8621 12.2254 15.0379C12.0496 15.2137 11.8111 15.3125 11.5625 15.3125Z"
              fill="#022438"
            />
          </svg>
          <span v-if="!transactionsExportLoading" class="d-none d-md-block">Filter</span>
          <span v-else class="d-none d-md-block">Loading...</span>
        </button>
      </div>
    </div>

    <div class="card b-card">
      <div class="card-body">
        <div class="table-filter mb-3 p-3" v-if="showFilter">
          <div class="row align-items-end">
            <div class="col-12 col-sm-6 col-md-4 t-col">
              <label class="f-13">Date</label>
              <div class="rqd-inputs">
                <input type="date" class="rqd-input" v-model="filter.from" />
                <span class="d-block"> to </span>
                <input type="date" class="rqd-input" v-model="filter.to" />
              </div>
            </div>
            <div class="col-6 col-md-4 t-col">
              <label class="f-13">Mode</label>
              <multiselect v-model="filter.mode" :options="mode" label="name" name="value"> </multiselect>
            </div>
            <div class="col-6 col-md-4 t-col">
              <label class="f-13">Status</label>
              <multiselect v-model="filter.status" :options="status" label="name" name="value"> </multiselect>
            </div>
            <div class="col-6 col-md-4 t-col">
              <label class="f-13">Expense Category</label>
              <multiselect v-model="filter.expense" :options="categories" label="title" name="id"> </multiselect>
            </div>
            <div class="col-6 col-md-4 t-col" style="margin-bottom: 2px">
              <div class="btn-filter">
                <button class="btn btn-primary f-13" @click.prevent="filterData()">Search</button>
                <button class="btn btn-primary f-13 ms-1" @click.prevent="toggleFilter(true)">Close</button>
              </div>
            </div>
          </div>
          <div class="row"></div>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="tb-sd">Transaction ID</th>
                <th>Amount</th>
                <th>Fee</th>
                <th>Mode</th>
                <th>Business purpose</th>
                <th>Status</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-if="transactions.transactions.length > 0">
              <tr v-for="(trans, index) in transactions.transactions" :key="index">
                <!-- <td>
                  <span v-if="pagination.current == 1">
                    {{ ++index }}
                  </span>
                  <span v-else>
                    {{
                      ++index +
                      (pagination.current - 1) * transactions.records_per_page
                    }}
                  </span>
                </td> -->
                <td class="td-sd">{{ trans.transaction_no }}</td>
                <td>₦{{ formatPrice(trans.amount) }}</td>
                <td>₦{{ formatPrice(trans.fees) }}</td>
                <td>
                  <span v-if="trans.mode == 1">Credit</span>
                  <span v-if="trans.mode == 2">Purchases</span>
                  <span v-if="trans.mode == 3">Payment</span>
                  <span v-if="trans.mode == 4">Offline Payment</span>
                  <span v-if="trans.mode == 6">Subscription</span>
                  <span v-if="trans.mode == 7">Fee refund</span>
                </td>

                <td>
                  {{ trans.business_purpose }}
                </td>
                <td>
                  <span class="badge badge-success" v-if="trans.status == 1">credit</span>
                  <span class="badge badge-danger" v-else>debit</span>
                </td>

                <td>
                  {{ formatDateTime(trans.date) }}
                </td>
                <td>
                  <div v-if="trans.mode == 2">
                    <router-link
                      :to="{ name: 'order', params: { id: trans.mode_id } }"
                      class="btn-view"
                      v-if="trans.type.toLocaleLowerCase() == 'konga' || trans.type.toLocaleLowerCase() == 'jumia'"
                    >
                      <i class="fas fa-eye"></i>
                    </router-link>
                    <router-link v-else :to="'bill/' + trans.mode_id" class="btn-view">
                      <i class="fas fa-eye"></i>
                    </router-link>
                  </div>

                  <router-link v-if="trans.mode == 3 || trans.mode == 4" :to="{ name: 'ShowPayment', params: { id: trans.mode_id } }" class="btn btn-view">
                    <i class="fa fa-eye"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
            <NoData v-else :data="{ colspan: 8, message: 'No result found' }"></NoData>
          </table>
        </div>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-end">
            <li class="page-item" v-if="pagination.current != 1" :class="pagination.current == 1 ? 'disabled' : ''">
              <a class="page-link" href="#" @click.prevent="getTransactions(pagination.current - 1)" tabindex="-1" aria-disabled="true">Previous</a>
            </li>
            <li class="page-item" v-for="(item, index) in pagination.pagLimit" :key="index" :class="item == pagination.current ? 'active' : ''">
              <a v-if="item <= pagination.last" class="page-link" @click.prevent="paginationClick(item)" href="#">{{ item }}</a>
            </li>
            <li class="page-item" v-if="pagination.last > pagination.pagLimit">
              <select @change="nextPage($event)" class="select-page-link" :class="pagination.current > pagination.pagLimit ? 'active' : ''">
                <option value="">...</option>
                <option :value="item" v-for="(item, index) in pagination.last - pagination.pagLimit" :key="index">
                  {{ item + pagination.pagLimit }}
                </option>
              </select>
            </li>
            <li class="page-item" v-if="pagination.current != pagination.last && transactions.transactions.length > 0">
              <a class="page-link" @click="paginationClick(pagination.current + 1)" href="#">Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
</template>

<script>
import NoData from '@/components/dashboard/noData.vue';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
export default {
  components: { NoData, Multiselect },
  data() {
    return {
      transactions: [],
      showFilter: false,
      loaded: false,
      categories: [],
      pagination: {
        last: 2,
        current: 1,
        pagLimit: 6,
      },
      filter: {
        from: '',
        to: '',
        expense: '',
        mode: { value: 'all', name: 'All' },
        status: { value: 'all', name: 'All' },
      },
      query: '',
      hasFilter: false,
      endpoint: 'company/transactions',
      mode: [
        { value: 'all', name: 'All' },
        { name: 'Credit', value: 1 },
        { name: 'Purchases', value: 2 },
        { name: 'Payment', value: 3 },
        { name: 'Offline Payment', value: 4 },
        { name: 'Subscription', value: 6 },
        { name: 'Fee refund', value: 7 },
      ],
      status: [
        { value: 'all', name: 'All' },
        { value: 1, name: 'Credit' },
        { value: 2, name: 'Debit' },
      ],
      expenseFilter: false,
      unclassifiedDefault: { title: 'Unclassified', id: null },
      transactionsExportLoading: false,
    };
  },
  methods: {
    toggleFilter(close = false) {
      this.showFilter = !this.showFilter;
      if (close) {
        this.hasFilter = false;
        this.getTransactions();
      }
    },
    exportToExcel() {
      this.$store.commit('setLoader', true);

      var endpoint = `${this.$store.state.endpoint}company/report/transactions`;

      if (this.hasFilter) {
        endpoint += `?${this.query}mode=${this.filter.mode.value}`;
      }

      axios.get(endpoint, { responseType: 'blob', headers: {
          Authorization: 'Bearer ' + this.$store.state.token,
        }, }).then((res) => {
        this.$store.commit('setLoader', false);
        var FILE = window.URL.createObjectURL(new Blob([res.data]));

        var docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', 'transaction.xlsx');
        window.document.body.appendChild(docUrl);
        docUrl.click();
      });
    },
    nextPage(no) {
      var cal = Number(no.target.value) + Number(this.pagination.pagLimit);
      this.paginationClick(cal);
    },
    paginationClick(page) {
      if (this.hasFilter) {
        this.filterData(page);
      } else {
        this.getTransactions(page);
      }
    },

    filterData(page = 1) {
      this.query = '';
      if (this.filter.from) {
        this.query += `from=${this.filter.from}&`;
      }
      if (this.filter.to) {
        this.query += `to=${this.filter.to}&`;
      }
      if (this.filter.expense) {
        this.query += `expense=${this.filter.expense.id}&`;
      }
      // console.log(this.filter);
      this.$store.commit('setLoader', true);
      this.$store
        .dispatch('get', `${this.endpoint}?${this.query}status=${this.filter.status.value}&mode=${this.filter.mode.value}&page=${page}`)
        .then((resp) => {
          this.$store.commit('setLoader', false);
          this.hasFilter = true;
          this.transactions = resp.data;
          this.setPagination(page);
          this.loaded = true;
        })
        .catch(() => {
          this.$store.commit('setLoader', false);
        });
    },
    getTransactions(page = 1) {
      this.$store.commit('setLoader', true);
      this.$store.dispatch('get', `${this.endpoint}?page=${page}`).then((resp) => {
        this.$store.commit('setLoader', false);
        this.transactions = resp.data;
        console.log(this.transactions);
        this.setPagination(page);
        this.loaded = true;
      });
    },
    setPagination(page) {
      this.pagination.current = page;
      this.pagination.last = Math.ceil(this.transactions.total / this.transactions.records_per_page);
    },
    async getExpenses() {
      await this.$store.dispatch('get', `company/category`).then((resp) => {
        this.categories = resp.data;
        this.categories.unshift({ ...this.unclassifiedDefault });
      });
    },
  },
  async created() {
    await this.getExpenses();

    if (this.$route.query.expense) {
      this.$store.commit('setLoader', true);
      this.filter.expense = this.categories.find((item) => item.id == this.$route.query.expense);

      this.filter.from = this.$route.query.from;
      this.filter.to = this.$route.query.to;

      console.log(this.filter);
      if (this.$route.query.expense == 'null') {
        this.filter.expense = { ...this.unclassifiedDefault };
        // console.log(this.filter.expense);
      }
      this.expenseFilter = true;
      this.hasFilter = true;
      this.filterData();
    } else {
      this.getTransactions();
    }
  },
};
</script>

<style>
.table-filter .multiselect__placeholder {
  margin-bottom: 0 !important;
}
.table-filter .multiselect__tags {
  min-height: 38px;
  padding: 6px 40px 0 8px;
  border-radius: 0;
}
.table-filter .multiselect__input,
.table-filter .multiselect__single {
  font-size: 13px;
  line-height: 22px;
}
</style>

<style scoped lang="scss">
.tranc-header {
  margin-bottom: 24px;
}

.btn-outline {
  color: #022438;
  border: 1px solid #022438;
  border-radius: 8px;
}
.btn-darkBlue {
  width: 140px;
  color: #fff;
  background: #022438;
  border: 1px solid #022438;
  border-radius: 8px;
}

.rqd-inputs {
  display: flex;
  width: 100%;
  border: 1px solid #e8e8e8;
  padding: 8px 10px;
  font-size: 13px;
  justify-content: space-between;
  span {
    margin: 0 10px;
  }
  input {
    border: none;
    box-shadow: none;
    outline: none;
  }
}
.btn-filter {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .tranc-header {
    margin-bottom: 15px;
  }
  .btn-darkBlue {
    width: fit-content;
  }
}
</style>
